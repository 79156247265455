<template>
  <div class="live-answer gray-bcg-color">
    <c-banner :bannerData="bannerData"></c-banner>
    <div class="advantage c-width">
      <c-title :titleArr="advantageTitle"></c-title>
      <div class="advantage-list">
        <div
          class="advantage-item"
          v-for="(item, index) in advantageData"
          :key="index"
        >
          <el-image class="img" :src="item.src" alt="" lazy></el-image>
          <div class="title">{{ item.title }}</div>
          <div class="line"></div>
          <div class="content">
            <div class="text" v-for="text in item.textData" :key="text">
              {{ text }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="framework white_bcg-color">
      <div class="c-width">
        <c-title :titleArr="frameTitle"></c-title>
        <div class="img-wrap">
          <div>
            <el-image
              :src="require('../../../assets/images/answer/solution@2x.png')"
              lazy
              alt=""
            ></el-image>
          </div>
          <div>
            <el-image
              :src="
                require('../../../assets/images/answer/solution_txt@2x.png')
              "
              lazy
              alt=""
            ></el-image>
          </div>
        </div>
      </div>
    </div>
    <div class="product">
      <c-title :titleArr="productTitle"></c-title>
      <c-product :productData="productData"></c-product>
    </div>
    <div class="scene c-width">
      <c-title class="title" :titleArr="sceneTitle"></c-title>
      <c-scene :sceneData="sceneData"></c-scene>
    </div>
    <div class="doc">
      <div class="doc-content c-width">
        <c-title :titleArr="documentTitle" :isDocTitle="true"></c-title>
        <div class="content">
          <a :href="`${docSrc}docs/show/2.html`" target="_blank"
            >直播服务开发指南</a
          >
          <a :href="`${docSrc}docs/show/338.html`" target="_blank"
            >聊天服务开发指南</a
          >
          <a :href="`${docSrc}docs/show/726.html`" target="_blank"
            >直播答题开发指南</a
          >
        </div>
      </div>
    </div>
    <c-customized :href="bannerData.href2"></c-customized>
  </div>
</template>

<script>
import { cBanner, cProduct, cScene, cCustomized } from '../components'
import { cTitle } from '@/components'
export default {
  components: {
    cBanner,
    cProduct,
    cTitle,
    cScene,
    cCustomized
  },
  data () {
    return {
      docSrc: window.urlConfig.docUrl,
      documentTitle: ['产品文档'],
      sceneData: [
        {
          src: require('../../../assets/images/answer/scene1@2x.png'),
          text: '知识竞猜'
        },
        {
          src: require('../../../assets/images/answer/scene2@2x.png'),
          text: '有奖竞答'
        },
        {
          src: require('../../../assets/images/answer/scene3@2x.png'),
          text: '学术互动'
        },
        {
          src: require('../../../assets/images/answer/scene4@2x.png'),
          text: '企业年会'
        }
      ],
      sceneTitle: ['应用场景'],
      productData: [
        {
          className: 'live',
          title: '直播服务',
          text: '全流程直播服务，快速部署搭建直播业务',
          href: `//${window.location.host}/live`
        },
        {
          className: 'chat',
          title: '聊天服务',
          text: '完整的聊天解决方案，多终端通讯功能支持',
          href: `//${window.location.host}/chat`
        }
      ],
      productTitle: ['方案产品'],
      advantageTitle: ['毫秒级演示', '重现直播答题高互动'],
      frameTitle: ['方案架构'],
      bannerData: {
        ways: 'live-answer',
        title: '微吼云直播答题企业级解决方案',
        text: [
          '题画同步，超低延时，百万级并发，1天接入',
          '满足不同行业客户的需求，打造流畅的直播答题互动体验'
        ],
        href2: `//${window.location.host}/custom?refer=answer`,
        href1: 'https://vhall.s4.udesk.cn/im_client/?web_plugin_id=15038',
        text1: '咨询我们',
        text2: '申请定制'
      },
      advantageData: [
        {
          src: require('../../../assets/images/answer/advantage1_1@2x.png'),
          title: '高并发 低延时',
          textData: [
            '依托微吼云强大的实时转码与全球分发能力',
            '支撑题目下发、结果统计和结果校验的超高并发',
            '并保障直播答题各环节稳定流畅、低延时'
          ]
        },
        {
          src: require('../../../assets/images/answer/advantage2_1@2x.png'),
          title: '稳定可靠 低风险',
          textData: [
            '为主持人打造题词系统',
            '支持题目撤回及直播中的题目变更',
            '降低直播中产生意外的风险'
          ]
        },
        {
          src: require('../../../assets/images/answer/advantage3_1@2x.png'),
          title: '音、题、画同步',
          textData: [
            '将音视频与答题使用同一通道进行传输',
            '实现声音、画面和题目同步弹出',
            '打造更好的互动答题体验'
          ]
        },
        {
          src: require('../../../assets/images/answer/advantage4_1@2x.png'),
          title: '丰富的产品形态',
          textData: [
            '支持复活卡、兑换码等促活功能',
            '支持美颜、滤镜、动画特效、主持人虚拟背景、关',
            '键词实时过滤等功能，全面满足运营需求'
          ]
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../assets/less/varible.less';
.marginTop {
  margin-top: 40px;
}
.advantage-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 50px;
  .advantage-item {
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 410px;
    justify-content: center;
    position: relative;
    width: 40%;
    margin-top: 40px;
    border-radius: 4px;
    &:nth-of-type(2),
    &:nth-of-type(4) {
      margin-left: 40px;
    }
    .img {
      width: 184px;
    }
    .title {
      margin-top: 30px;
      font-size: 24px;
      color: #333;
    }
    .line {
      width: 60px;
      height: 6px;
      margin-top: 30px;
      background: #555;
      border-radius: 6px;
    }
    .content {
      margin-top: 40px;
      text-align: center;
      display: none;
      .text {
        font-size: 18px;
        color: #666;
        line-height: 40px;
      }
    }
    &:hover {
      box-shadow: @boxShadow;
      .img {
        margin-top: -100px;
      }
      .content {
        display: block;
      }
    }
  }
}
.framework {
  .img-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 40px;
    img {
      width: 100%;
    }
  }
}
.doc {
  width: 100%;
  height: 236px;
  background: url('../../../assets/images/answer/doc_bg@2x.png') no-repeat;
  background-size: cover;
  margin-top: 50px;
  padding-top: 44px;
  .doc-content {
    .content {
      display: grid;
      grid-template-columns: repeat(3, 33.3%);
      margin-top: 50px;
      a {
        font-size: 18px;
        color: #e1e1e1;
        font-weight: 500;
        text-align: center;
        line-height: 32px;
        &:not(:first-child) {
          border-left: 1px solid #fff;
        }
        &:hover {
          color: #fff;
        }
      }
    }
  }
}
</style>
